import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Title, Container, Card, Hero } from "rbx"
import logoImage from "../../assets/images/logo-512.png"
import LoginForm from "./form"
import UsersService from "../../services/users"

const LoginView = (props) => {
  const [redirectToHome, setRedirectToHome] = useState(false)
  const [loading, setLoading] = useState(true)

  const loginCardRef = useRef()
  
  const handleAutoLogin = async () => {
    await UsersService.autoLogin()
    .then(() => {
      setRedirectToHome(true)      
    })
    .catch( (e) => {
      UsersService.logout()
      setLoading(false)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      loginCardRef.current.style.transform = "translateY(0px)"
      loginCardRef.current.style.opacity = 1
    }, 1)
  }, [])

  useEffect(()=>{    
    if (localStorage.getItem("auth-token")) {
      handleAutoLogin()
    } else {
      setLoading (false)
    }
  }, [])

  if (redirectToHome) {
    return <Redirect to="app/calendar"/>
  }

  return (
    <Hero color="custom-green-dark" size="fullheight">
      <Hero.Body className="pt-3 px-3">
        <Container>
          <Column.Group centered>
            <Column size={4}>
              <Card ref={loginCardRef} className="login-card">
                <Card.Content>
                  
                  <Column.Group centered breakpoint="mobile" textAlign="centered">
                    <Column size={5} >
                      <img src={logoImage} alt="logo"/>
                    </Column>
                  </Column.Group>

                  <Column.Group>
                    <Column size={12} className="pt-0">
                      <Title size={2} className="has-text-custom-green-dark has-text-centered">
                        CliniWeb
                      </Title>
                      <Title subtitle size={6} className="has-text-grey has-text-centered">
                        Gestão descomplicada
                      </Title>
                    </Column>
                  </Column.Group>

                  <LoginForm loading={loading}/>
                    
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>
        </Container>
      </Hero.Body>
    </Hero>
  )};

export default LoginView;