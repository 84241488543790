import React, { Fragment, useState, useEffect, useRef, createRef } from 'react';
import { Redirect } from "react-router-dom";
import { Column, Section, Card, Title, Dropdown, Icon, Button } from "rbx"
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import NotificationOverlay from "../../notification-overlay"
import WorkspaceTitle from "../title"
import UsersService from "../../../../services/users"
import EventsService from "../../../../services/events"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list' 
import ptbrLocale from '@fullcalendar/core/locales/pt-br'
import interactionPlugin from '@fullcalendar/interaction'
import calendar from "../../../../config/calendar"
import { faChartLine, faDollarSign, faHouseChimney, faMoneyBillTrendUp, faX } from '@fortawesome/free-solid-svg-icons';

const Home = (props) => {

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }

  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const [openPayments, setOpenPayments] = useState(null)

  const [events, setEvents] = useState([])
  const [professionals, setProfessionals] = useState([])
  const [filters, setFilters] = useState({
    statusPending: true,
    statusDone: true,
    statusPatientNotPresent: true,
    noCost: true,
    paid: true,
    notPaid: true,
  })

  const [eventPreviewInfo, setEventPreviewInfo] = useState(null)
  const [eventPreviewHeight, setEventPreviewHeight] = useState(null)
  const calendarScrollTop = useRef(0)
  const calendarScrollerRef = useRef(null)

  const [eventClickInfo, setEventClickInfo] = useState(null)

  const eventPreviewRef = createRef()

  const getAndSetEvents = async () => {
    setLoading(true)
    await EventsService.getEvents()
    .then( (response) => {
      let events = response.data
      let eventsToRender = []
      let professionalsToSet = []
      events.map( (event) => {
        event.color = event.professionalInfo[0].eventsColor
        event.professional = event.professionalInfo[0].name
        event.patient = event.patientInfo[0].name
        event.attendancePackage = event.attendancePackage[0] ? event.attendancePackage[0].name : null
        event.title = `${event.patient.split(" ")[0]} [${event.professional.split(" ")[0].toUpperCase()}]`
        if (event.patientInfo[0].phoneNumber) {
          event.patientPhoneNumber = `${event.patientInfo[0].areaCode.lenght > 0 ? `(${event.patientInfo[0].areaCode}) ` : ""}${methods.maskAndSetPhoneNumber(event.patientInfo[0].phoneNumber)}`
        }
        eventsToRender.push(event)
        if (!professionalsToSet.some( (professional) => {
          return professional.id == event.professionalId
        })) {
          professionalsToSet.push({
            name: event.professionalInfo[0].name,
            id: event.professionalId,
            eventsColor: event.professionalInfo[0].eventsColor,
            show: true,
          })
        }
        setProfessionals(professionalsToSet)
      })
      setEvents(eventsToRender)
      setLoading(false)
    })
    .catch(function (error) {
      console.log(error)
      try {
        if (error.response.status == 401) {
          setLoading(false)
          setNotification({
            success: false,
            text: "Erro de autenticação. Você será redirecionado para a tela de login",
            callback: ( () => {
              setNotification(false)
              UsersService.logout()
              setRedirectToLogin(true)
            })
          })
        } else {
          setLoading(false)
          setNotification({
            success: false,
            text: error.response.data,
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      } catch {
        setLoading(false)
        setNotification({
          success: false,
          text: "Não foi possível atualizar as informações. Tente novamente",
          callback: ( () => {
            setNotification(false)
          })
        })
      }
    })
  }

  const renderEventContent = (eventInfo) => {
    let paid = 0
    if (eventInfo.event._def.extendedProps.payments) {
      eventInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    if (eventInfo.event._def.extendedProps.attendancePackageId) {
      paid = "Pacote de atendimento"
    } else if (eventInfo.event._def.extendedProps.price == 0 || !eventInfo.event._def.extendedProps.price) {
      paid = "Sem cobrança"
    } else if (eventInfo.event._def.extendedProps.price > paid) {
      paid = "Pendente"
    } else if (eventInfo.event._def.extendedProps.price <= paid) {
      paid = "Pago"
    } 
    const paidElement = <span className={paid == "Pacote de atendimento" ? "has-background-primary has-text-white ml-2 event-icon" : paid == "Sem cobrança" ? "has-background-grey has-text-white ml-2 event-icon" : paid == "Pago" ? "has-background-success has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon"} >{paid == "Pacote de atendimento" ? "▣" : "$"}</span>
    const eventStatus = eventInfo.event._def.extendedProps.status
    const statusElement = <span className={eventStatus == "Atendido" ? "has-background-success has-text-white ml-2 event-icon" : (eventStatus == "Aguard. atendimento" ? "has-background-info has-text-white ml-2 event-icon" : "has-background-danger has-text-white ml-2 event-icon") } >{eventStatus == "Atendido" ? "✓" : (eventStatus == "Aguard. atendimento" ? "⧗" : "✕")}</span>
    return (
      <span>
        <a>{eventInfo.event._def.title}</a>
        {statusElement}
        {paidElement}
      </span>
    )
  }

  const handleEventMouseEnter = (mouseEnterInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    if (!eventClickInfo) {
      let element = mouseEnterInfo.jsEvent.target
      if (/fc-event-title\sfc-sticky|event-icon/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement.parentElement
      } else if (/fc-event-title-container|fc-event-time/.test(element.classList.value)) {
        element = element.parentElement.parentElement.parentElement
      } else if (/fc-event-main-frame|fc-event-resizer/.test(element.classList.value)) {
        element = element.parentElement.parentElement
      } else if (/fc-event-main/.test(element.classList.value)) {
        element = element.parentElement
      }
      let rect = element.getBoundingClientRect()
      let left = window.innerWidth - rect.left > 260 ? rect.left : null
      let right = left == null ? window.innerWidth - rect.right : null
      let start = null
      let end = null
      let price = mouseEnterInfo.event._def.extendedProps.price ? mouseEnterInfo.event._def.extendedProps.price : 0
      price = methods.numberToPrice(price)
      let paid = 0
      if (mouseEnterInfo.event._def.extendedProps.payments) {
        mouseEnterInfo.event._def.extendedProps.payments.map( (payment) => {
          if (payment) {
            paid += payment.value
          }
        })
      }
      let debt = price != 0 ? mouseEnterInfo.event._def.extendedProps.price - paid : 0
      debt = methods.numberToPrice(debt.toFixed(2))
      paid = methods.numberToPrice(paid.toFixed(2))
      setEventPreviewInfo({
        attendancePackage: mouseEnterInfo.event._def.extendedProps.attendancePackage,
        attendancePackageId: mouseEnterInfo.event._def.extendedProps.attendancePackageId,
        top: rect.top,
        left: left,
        right: right,
        start: start,
        end: end,
        professional: mouseEnterInfo.event._def.extendedProps.professional,
        patient: mouseEnterInfo.event._def.extendedProps.patient,
        description: mouseEnterInfo.event._def.extendedProps.description,
        status: mouseEnterInfo.event._def.extendedProps.status,
        price: price,
        paid: paid,
        debt: debt
      })
    }
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }
  
  const handleEventMouseLeave = (mouseLeaveInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    setEventPreviewInfo(null)
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }

  const handleEventClick = (eventClickInfo) => {
    calendarScrollerRef.current = document.getElementsByClassName('fc-scroller')[1]
    calendarScrollerRef.current ? calendarScrollTop.current = calendarScrollerRef.current.scrollTop : null
    setEventPreviewInfo(null)
    let price = eventClickInfo.event._def.extendedProps.price ? eventClickInfo.event._def.extendedProps.price : 0
    price = methods.numberToPrice(price)
    let paid = 0
    if (eventClickInfo.event._def.extendedProps.payments) {
      eventClickInfo.event._def.extendedProps.payments.map( (payment) => {
        if (payment) {
          paid += payment.value
        }
      })
    }
    let debt = price != 0 ? eventClickInfo.event._def.extendedProps.price - paid : 0
    debt = methods.numberToPrice(debt.toFixed(2))
    paid = methods.numberToPrice(paid.toFixed(2))
    setEventClickInfo({
      attendancePackage: eventClickInfo.event._def.extendedProps.attendancePackage,
      attendancePackageId: eventClickInfo.event._def.extendedProps.attendancePackageId,
      id: eventClickInfo.event._def.extendedProps._id,
      recurrenceId: eventClickInfo.event._def.extendedProps.recurrenceId,
      recurrenceWeekdays: eventClickInfo.event._def.extendedProps.recurrenceWeekdays,
      date: eventClickInfo.event.start,
      start: eventClickInfo.event.start,
      end: eventClickInfo.event.end,
      patient: eventClickInfo.event._def.extendedProps.patient,
      patientId: eventClickInfo.event._def.extendedProps.patientId,
      patientPhone: eventClickInfo.event._def.extendedProps.patientPhone,
      professional: eventClickInfo.event._def.extendedProps.professional,
      professionalId: eventClickInfo.event._def.extendedProps.professionalId,
      description: eventClickInfo.event._def.extendedProps.description,
      status: eventClickInfo.event._def.extendedProps.status,
      payments: eventClickInfo.event._def.extendedProps.payments,
      price: price,
      paid: paid,
      debt: debt,
      hasRegisteredAttendance: eventClickInfo.event._def.extendedProps.attendanceDateTime ? true : false,
    })
    calendarScrollerRef.current ? calendarScrollerRef.current.scrollTop = calendarScrollTop.current : null
  }

  useEffect( () => {
    getAndSetEvents()
    window.oncontextmenu = (event) => {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }, [])

  useEffect( () => {
    if (eventPreviewRef.current) {
      setEventPreviewHeight(eventPreviewRef.current.clientHeight)
    }
  }, [eventPreviewInfo])

  if (redirectToLogin) {
    return <Redirect to="/"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}
      {notification && <NotificationOverlay notification={notification} />}
      {eventPreviewInfo &&
        <Components.EventPreview
          eventPreviewRef={eventPreviewRef}
          eventPreviewInfo={eventPreviewInfo}
          eventPreviewHeight={eventPreviewHeight}
          />
      }

      <WorkspaceTitle >
        <Column.Group vcentered breakpoint="mobile" className="m-0" >
          <Column narrow className="py-0 px-0" >
            <span className="pr-3"><FontAwesomeIcon icon={faHouseChimney} /></span>
          </Column>
          <Column narrow className="py-0 px-0" >
            <span className="mr-5">Início</span>
          </Column>
        </Column.Group>
      </WorkspaceTitle>

      <Column.Group multiline breakpoint="desktop">

        <Column className="is-6-desktop is-4-fullhd">
          <Card className="home-card">

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title"><FontAwesomeIcon icon={faCalendarCheck} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Agendamentos de hoje
                  </Title>
                </Column>
              </Column.Group>

              {eventClickInfo &&
                <Components.EventClickOverlay        
                  eventClickInfo={eventClickInfo}
                  setEventClickInfo={setEventClickInfo}
                  setOpenPayments={setOpenPayments}
                  setFocusedItem={props.setFocusedItem}
                  setNotification={setNotification}
                  setFormChanged={setFormChanged}
                  setLoading={setLoading}
                  getAndSetEvents={getAndSetEvents}
                  setRedirectToLogin={setRedirectToLogin}
                  setObjectToEdit={props.setObjectToEdit}
                />
              }

              {openPayments &&
                <Components.PaymentsOverlay
                  eventClickInfo={eventClickInfo}
                  setOpenPayments={setOpenPayments}
                  setEventClickInfo={setEventClickInfo}
                  getAndSetEvents={getAndSetEvents}
                  setFormChanged={setFormChanged}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              }

            </Section>

            <Section className="py-3 px-3" style={{ whiteSpace: "nowrap" }}>

              <FullCalendar
                plugins={[ listPlugin, interactionPlugin ]}
                locale={ptbrLocale}
                height="350px"
                expandRows={true}
                headerToolbar={false}
                listDayFormat={{
                  month: 'long',
                  year: 'numeric',
                  day: 'numeric',
                  weekday: 'long',
                }}
                slotLabelFormat={
                  {
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    meridiem: 'short'
                  }
                }
                stickyHeaderDates={true}
                initialView="listDay"

                editable={true}

                events={events}

                eventContent={renderEventContent}

                eventMouseEnter={handleEventMouseEnter}
                eventMouseLeave={handleEventMouseLeave}
                eventClick={handleEventClick}

                noEventsContent="Não há agendamentos para hoje"

              />

            </Section>

          </Card>
        </Column>

        <Column className="is-6-desktop is-4-fullhd">
          <Card className="home-card" >

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title has-text-danger"><FontAwesomeIcon icon={faX} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Atendimentos atrasados
                  </Title>
                </Column>
              </Column.Group>

            </Section>

            <Section className="py-3 px-3 thin-scroller" style={{ whiteSpace: "nowrap", height: "369px", overflowY: "scroll" }}>
              <Components.GetPendingAttendancesResults
                setEventPreviewInfo={setEventPreviewInfo}
                setFocusedItem={props.setFocusedItem}
                setObjectToEdit={props.setObjectToEdit}
                setNotification={setNotification}
                setFormChanged={setFormChanged}
                setLoading={setLoading}
                setRedirectToLogin={setRedirectToLogin}
              />
            </Section>

          </Card>
        </Column>

        <Column className="is-6-desktop is-4-fullhd is-hidden-touch is-hidden-desktop-only is-hidden-widescreen-only">
          <Card className="home-card">

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title has-text-success"><FontAwesomeIcon icon={faMoneyBillTrendUp} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Fluxo de caixa
                  </Title>
                </Column>
              </Column.Group>

            </Section>

          </Card>
        </Column>

        <Column className="is-12-desktop is-8-fullhd">
          <Card className="home-card">

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title has-text-danger"><FontAwesomeIcon icon={faDollarSign} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Pendências financeiras
                  </Title>
                </Column>
              </Column.Group>

            </Section>

            <Section className="py-3 px-3 thin-scroller" style={{ whiteSpace: "nowrap", height: "369px", overflowY: "scroll" }}>
              <Components.GetPendingFinancesResults
                setEventPreviewInfo={setEventPreviewInfo}
                setFocusedItem={props.setFocusedItem}
                setObjectToEdit={props.setObjectToEdit}
                setNotification={setNotification}
                setFormChanged={setFormChanged}
                setLoading={setLoading}
                setRedirectToLogin={setRedirectToLogin}
              />
            </Section>

          </Card>
        </Column>

        <Column className="is-6-desktop is-4-fullhd is-hidden-fullhd">
          <Card className="home-card">

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title has-text-success"><FontAwesomeIcon icon={faMoneyBillTrendUp} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Fluxo de caixa
                  </Title>
                </Column>
              </Column.Group>

            </Section>

          </Card>
        </Column>

        <Column className="is-6-desktop is-4-fullhd">
          <Card className="home-card">

            <Section className="py-0 px-4">

              <Column.Group vcentered className="m-0 pt-3 calendar-title" breakpoint="mobile">
                <Column narrow className="p-0">
                  <span className="pr-3 calendar-title"><FontAwesomeIcon icon={faChartLine} /></span>
                </Column>
                <Column className="p-0">
                  <Title className="calendar-title mb-0">
                    Relatório
                  </Title>
                </Column>
              </Column.Group>

            </Section>

          </Card>
        </Column>

      </Column.Group>


    </Section>
  )
}

export default Home;