import Api from "./api"

const RevenuesOrExpensesService = {

  newRevenueOrExpense: async (params) => {
    const response = await Api.post("/revenuesAndExpenses/newRevenueOrExpense", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
  },
  
  editRevenueOrExpense: async (params) => {
    let response = await Api.post("/revenuesAndExpenses/editRevenueOrExpense", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getRevenuesAndExpenses: async (params) => {
    let response = await Api.post("/revenuesAndExpenses/getRevenuesAndExpenses", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteRevenueOrExpense: async (params) => {
    let response = await Api.post("/revenuesAndExpenses/deleteRevenueOrExpense", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  registerPayment: async (params) => {
    let response = await Api.post("/revenuesAndExpenses/registerPayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deletePayment: async (params) => {
    let response = await Api.post("/revenuesAndExpenses/deletePayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default RevenuesOrExpensesService