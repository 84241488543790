import Api from "./api"

const AttendancesService = {

  newAttendance: async (params) => {
    const response = await Api.post("/attendances/newAttendance", params,
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
          "keep-logged": localStorage.getItem("keep-logged"),
        }
      }
    )
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },
  
  editAttendance: async (params) => {
    let response = await Api.put("/attendances/editAttendance", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      "Content-Type": `multipart/form-data; boundary=${params._boundary}`,
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendanceById: async (params) => {
    let response = await Api.post("/attendances/getAttendanceById", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchAttendances: async (params) => {
    let response = await Api.post("/attendances/searchAttendances", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getPendingAttendances: async (params) => {
    let response = await Api.post("/attendances/getPendingAttendances", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  searchAttendancesByPatientId: async (params) => {
    let response = await Api.post("/attendances/searchAttendancesByPatientId", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  getAttendanceDocuments: async (params) => {
    let response = await Api.post("/attendances/getAttendanceDocuments", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendanceDocument: async (params) => {
    let response = await Api.post("/attendances/deleteAttendanceDocument", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged"),
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendance: async (params) => {
    let response = await Api.post("/attendances/deleteAttendance", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  registerAttendancePayment: async (params) => {
    let response = await Api.post("/attendances/registerAttendancePayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

  deleteAttendancePayment: async (params) => {
    let response = await Api.post("/attendances/deleteAttendancePayment", params,
    {
      headers: {
      "auth-token": localStorage.getItem("auth-token"),
      "keep-logged": localStorage.getItem("keep-logged") 
      }
    })
    localStorage.setItem("auth-token", response.headers["auth-token"])
    return response
  },

}

export default AttendancesService