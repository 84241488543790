import React, { useState, useEffect, Fragment } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { Column, Section, Title, Button, Icon, Generic, Label } from "rbx"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Components, { methods } from "../methods-and-components/methods-and-components"
import LoadingOverlay from "../../loading-overlay"
import WorkspaceTitle from "../title"
import UsersService from "../../../../services/users"
import PatientsService from "../../../../services/patients"
import AttendancesService from "../../../../services/attendances"
import AttendancePackagesService from "../../../../services/attendancePackages"
import ClinicalExamsService from "../../../../services/clinicalExams"
import "../../../../styles/workspace.scss"
import "../../../../styles/loading.scss"
import { quantityTypes } from "../../../../constants"
import { faEdit,faDownload } from '@fortawesome/free-solid-svg-icons'
import { faAddressCard, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { DateTime } from 'luxon';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js'
import StateToPdfMake from "draft-js-export-pdfmake"
import pdfMake, { createPdf } from "pdfmake/build/pdfmake.min"

const EditPatient = (props) => {
  const [patient, setPatient] = useState(props.objectToEdit)

  const [name, setName] = useState("")
  const [nameError, setNameError] = useState(null)

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(null)

  const [gender, setGender] = useState("")

  const [rgIdentifier, setRgIdentifier] = useState("")
  const [rgIdentifierError, setRgIdentifierError] = useState(null)
  const [rgDigit, setRgDigit] = useState("")
  const [rgUf, setRgUf] = useState("")

  const [cpf, setCpf] = useState("")
  const [cpfError, setCpfError] = useState(null)

  const [birthday, setBirthday] = useState("")
  const [birthdayError, setBirthdayError] = useState(null)
  
  const [areaCode, setAreaCode] = useState("")
  const [areaCodeError, setAreaCodeError] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneNumberError, setPhoneNumberError] = useState(null)

  const [cep, setCep] = useState("")
  const [cepError, setCepError] = useState(null)
  const [street, setStreet] = useState("")
  const [adressNumber, setAdressNumber] = useState("")
  const [complement, setComplement] = useState("")
  const [neighborhood, setNeighborhood] = useState("")
  const [city, setCity] = useState("")
  const [uf, setUf] = useState("")

  const [createdAt, setCreatedAt] = useState("")
  const [isActive, setIsActive] = useState(false)

  const [previousAttendances, setPreviousAttendances] = useState([])

  const [previousAttendancePackages, setPreviousAttendancePackages] = useState([])

  const [clinicalExams, setClinicalExams] = useState([])

  const [activeTab, setActiveTab] = useState("1")

  const [chartImages, setChartImages] = useState([])
  const [src, setSrc] = useState(null)

  const clinicalExamsComponent =
  <Components.ClinicalExams
    attendance={null}
    clinicalExams={clinicalExams}
    setClinicalExams={null}
    setFormChanged={setFormChanged}
    setHasUnsavedChanges={setHasUnsavedChanges}
    setNotification={setNotification}
    setLoading={setLoading}
    setRedirectToLogin={setRedirectToLogin}
  />

  const [formChanged, _setFormChanged] = useState(false)
  const formChangedRef = React.useRef(formChanged)
  const setFormChanged = data => {
    formChangedRef.current = data
    _setFormChanged(data)
  }
  const [loading, setLoading] = useState(false)
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  const hasUnsavedChanges = props.hasUnsavedChanges
  const setHasUnsavedChanges = props.setHasUnsavedChanges
  const setNotification = props.setNotification

  let history = useHistory();

  const handleIsActiveChange = (checked) => {
    setIsActive(checked)
  }

  const mandatoryFields = [
    name,
  ]

  const errorList = [
    nameError,
    emailError,
    cpfError,
    birthdayError,
    areaCodeError,
    phoneNumberError,
    cepError,
  ]

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (methods.mandatoryFieldsNotFilled(mandatoryFields)) {
      setNotification({
        success: false,
        text: "Favor preencher todos os campos obrigatórios",
        callback: () => {
          setNotification(false)
        }
      })
    } else if (methods.hasErrors(errorList)) {
      setNotification({
        success: false,
        text: methods.errorsToShow(errorList),
        callback: () => {
          setNotification(false)
        }
      })
    } else {
      setLoading(true)
      let patientInfoToUpdate = {
        patientId: patient._id,
        name: name,
        email: email,
        areaCode: areaCode.replace(/\D/g,""),
        phoneNumber: phoneNumber.replace(/\D/g,""),
        gender: gender,
        rgIdentifier: rgIdentifier,
        rgDigit: rgDigit,
        rgUf: rgUf,
        cpf: cpf.replace(/\D/g,""),
        birthday: methods.stringToDate(birthday),
        cep: cep.replace(/\D/g,""),
        street: street,
        adressNumber: adressNumber,
        complement: complement,
        neighborhood: neighborhood,
        city: city,
        uf: uf,
        isActive: isActive,
      }
      await PatientsService.editPatient(patientInfoToUpdate)
      .then( () => {      
        setLoading(false)
        setNotification({
          success: true,
          text: "Informações do paciente atualizadas.",
          callback: () => {
            setNotification(false)
            setFormChanged(false)
            history.replace("/app/home")
          }
        })
      })
      .catch(function (error) {
        console.log(error)
        try {
          if (error.response.status == 401) {
            setLoading(false)
            setNotification({
              success: false,
              text: "Erro de autenticação. Você será redirecionado para a tela de login",
              callback: ( () => {
                setNotification(false)
                UsersService.logout()
                setRedirectToLogin(true)
              })
            })
          } else {
            setLoading(false)
            setNotification({
              success: false,
              text: error.response.data,
              callback: ( () => {
                setNotification(false)
              })
            })
          }
        } catch {          
          setLoading(false)
          setNotification({
            success: false,
            text: "Não foi possível atualizar as informações. Tente novamente",
            callback: ( () => {
              setNotification(false)
            })
          })
        }
      })
    }
  }

  const formatRecordsToPdf = () => {
    const editorStateToPdf = (args) => {
      const editorState = EditorState.createWithContent(convertFromRaw(args.rawEditorState))
      const rawContent = convertToRaw(editorState.getCurrentContent())
      const stateToPdfMake = new StateToPdfMake(rawContent)
      const docPdf = pdfMake.createPdf(stateToPdfMake.generate())
      docPdf.docDefinition.content.unshift({
        text: args.title,
        fontSize: 12,
        decoration: "underline",
        bold: true,
        margin: [0, 0, 0, 8]
      })
      args.stack.push(
        {
          stack: docPdf.docDefinition.content,
          margin: [20, 20, 0, 0],
          unbreakable: true
        },
      )
      return docPdf
    }
    const questionnaireToPdf = (args) => {
      args.stack.push({
          text: args.title,
          fontSize: 12,
          decoration: "underline",
          bold: true,
          margin: [20, 20, 0, 0]
        })
      args.questionnaire.questions.map((question, index) => (
        args.stack.push({
          stack: [
            { text: `Questão nº ${index + 1}:`, bold: true, color: "#dd1111", margin: [0, 15, 0, 0] },
            { text: question.question, bold: true, margin: [0, 4, 0, 0] },
            question.answerType == "Subjetiva" ? { text: question.answer, italics: true } : {},
            (question.answerType == "Objetiva" || question.answerType == "Múltipla") ? {
              stack: question.alternatives.map(alternative => (
                {
                  columns: [
                    {
                      stack: [{
                        image: 
                          question.answerType == "Objetiva" && alternative.chosen ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAIAAAD9iXMrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC9SURBVChTjZGxDcJAEASvCaogphEiKqADchdAREZAgERIREYHFjESogECMuJHXua8yLIg+dX7fL+3f7f2RylFPU7X93zfTRrFSkRyGJdA+LU8dtO1dhc9XrklksPA93VlPzaLg7c637VtMxrwlgbNOQduT802OdSLHAZQRRP4YAQYiwYpoIomsIwbBv2IvOCp5sexAXgal4cFDzKv7Vfrr/Z7a/8fj6WcYwRuAJEcxiIE33sDNMdHWv6/X+kD4fZ2UASIm/cAAAAASUVORK5CYII="
                          : question.answerType == "Objetiva" && !alternative.chosen ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAIAAAD9iXMrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADHSURBVChTlZExCoQwEEXdWQtB8AI2SWWvjU2u4AE8oAfwCvZpUlnpGQRBsNmHOyzIhoX95f9vwszP4zzP5NI8z977ZVn2fc/z3Fpb13VVVe9UuXEc13V1zhEURbFtG2PTNBljuq5TDug4jr7vr8mbhmHIsgxUmOOlKITwSWGebds2TVOWpSZfStM0hCAs/lk2KlIY4ToWVy8mUhihAq5TLyZSGKEn1lQvJlIYoUx6Ui8mUhhhTcqkJ7XvwieF+ec/rsmf/5skL5TKgaFST1ahAAAAAElFTkSuQmCC"
                          : question.answerType == "Múltipla" && alternative.chosen ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAIAAAD9iXMrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAC2SURBVChTY5xz4m/lDqbXXxlwAVFuhnaPf4yiDf/xKIIAoFJ8Ju1PZ0gwATGAapjAIlgAUJGDMkO9K5SLXV2DK0gRECSuAvOxqoMbA1R04C5YCK4O6I77lQwKgiAEtBEIFpwBIThgZCj9D6Qgih68Z3jwDmQe0BjHmRAFUABVBwQQpUAAVK3YDhZCAgj3AeWAKoAA7nZkgDAPAoAORXYWHDABwxoZYFUEig9g3KEpRQOg+PX4BwC32jqEvWWRnQAAAABJRU5ErkJggg=="
                          : question.answerType == "Múltipla" && !alternative.chosen ? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAIAAAD9iXMrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABVSURBVChTY3zy5MmmTZsePHjAgAMoKCj4+fkxTps2zcDAwNLSEiqMAY4fP37hwgUmoEl4FAEBUBaohgnKIwRG1WEHTMBoAYY4lIcNAGWBaoiLXz8/AFC9JTJYB3NyAAAAAElFTkSuQmCC"
                          : "",
                        width: 10,
                        height: 10
                      }],
                      width: 20,
                      margin: [0, 4, 0, 0]
                    },
                    alternative.alternative
                  ],
                }
              ))
            }
            :
            {},
          ],
          margin: [20, 0, 0, 0],
          unbreakable: true
        })
      ))
    }
    const procedureToPdf = (args) => {
      args.stack.push({
        text: args.title,
        fontSize: 12,
        decoration: "underline",
        bold: true,
        margin: [20, 20, 0, 0]
      })
      args.stack.push({
        text: args.procedure.notes,
        fontSize: 12,
        bold: false,
        margin: [20, 0, 0, 0]
      })
    }
    let counter = 1
    let recordsToPdf = []
      recordsToPdf.push({
        docDefinition:{
          content:{
            ol: [
              {
                stack: [
                  {
                    text: "INFORMAÇÕES BÁSICAS",
                    decoration: "underline",
                    fontSize: 14,
                    bold: true,
                    margin: [0, 10, 0, 0],
                  },
                  {
                    text: [
                      { text: "Nome: ", bold: true }, patient.name
                    ],
                    margin: [0, 20, 0, 0]
                  }, patient.email &&
                  {
                    text: [
                      { text: "E-mail: ", bold: true }, patient.email
                    ],
                    margin: [0, 5, 0, 0]
                  }, patient.phoneNumber &&
                  {
                    text: [
                      { text: "Telefone: ", bold: true }, `${patient.areaCode ? `(${patient.areaCode}) ` : ""}${methods.maskAndSetPhoneNumber(patient.phoneNumber)}`, 
                    ],
                    margin: [0, 5, 0, 0]
                  }, patient.gender &&
                  {
                    text: [
                      { text: "Sexo: ", bold: true }, patient.gender == "m" ? "Masculino" : patient.gender == "f" ? "Feminino" : "Não informado", 
                    ],
                    margin: [0, 5, 0, 0]
                  }, patient.rgIdentifier &&
                  {
                    text: [
                      { text: "RG: ", bold: true }, patient.rgIdentifier, patient.rgDigit && `-${patient.rgDigit}`, patient.rgUf && ` - ${patient.rgUf}`
                    ],
                    margin: [0, 5, 0, 0]
                  }, patient.cpf &&
                  {
                    text: [
                      { text: "CPF: ", bold: true }, methods.maskAndSetCpf(patient.cpf)
                    ],
                    margin: [0, 5, 0, 0]
                  }, patient.birthday &&
                  {
                    text: [
                      { text: "Data de nascimento: ", bold: true }, DateTime.fromJSDate(new Date(patient.birthday)).toLocaleString(DateTime.SHORT)
                    ],
                    margin: [0, 5, 0, 0]
                  },
                  
                ]
              },
              {
                stack: [
                  {
                    text: "ATENDIMENTOS",
                    decoration: "underline",
                    fontSize: 14,
                    bold: true,
                    margin: [0, 20, 0, 0],
                  },
                  {
                    stack: previousAttendances.map((attendance, index) => {
                      if (attendance.attendanceDateTime) {
                        if (index == 0) {
                          counter = 1
                        } else {
                          counter += 1
                        }
                        let attendanceToPdf = []
                        let { anamnesis, diagnosis, prognosis, terapeuticPlan, prescriptions, examRequests, attests, declarations, reports, consentTerms } = attendance
                        let { questionnaires, procedures } = attendance
                        attendanceToPdf.push(
                          {
                            stack: [
                              {
                                text: `Atendimento nº ${counter}${attendance.description ? ` - ${attendance.description}` : ""}`,
                                fontSize: 14,
                                bold: true,
                                italics: true,
                                decoration: "underline",
                                color: "#1144cc",
                                margin: [0, 20, 0, 0]
                              },
                              {
                                text: [
                                  {text: "Profissional: ", bold: true},
                                  `${attendance.professional}`
                                ],
                                fontSize: 12,
                                margin: [0, 3, 0, 0]
                              },
                              {
                                text: [
                                  {text: "Data: ", bold: true},
                                  `${DateTime.fromJSDate(new Date(attendance.attendanceDateTime)).toFormat("dd'/'MM'/'yyyy', às 'HH':'mm'")}`,
                                ],
                                fontSize: 12,
                                margin: [0, 3, 0, 0]
                              },
                            ],
                            unbreakable: true
                          },                        
                        )
                        if(anamnesis) editorStateToPdf({ rawEditorState: anamnesis, title: "Anamnese:", stack: attendanceToPdf })
                        if(questionnaires && questionnaires[0]) questionnaires.map((questionnaire, index) => questionnaireToPdf({ questionnaire: questionnaire, title: `Questionário nº ${index + 1}${questionnaire.name != "" ? ` - ${questionnaire.name}` : ""}`, stack: attendanceToPdf }))
                        if(procedures && procedures[0]) procedures.map((procedure, index) => procedureToPdf({ procedure: procedure, title: `Procedimento nº ${index + 1}${procedure.name != "" ? ` - ${procedure.name}` : ""}`, stack: attendanceToPdf }))
                        if(diagnosis) editorStateToPdf({ rawEditorState: diagnosis, title: "Diagnóstico:", stack: attendanceToPdf })
                        if(prognosis) editorStateToPdf({ rawEditorState: prognosis, title: "Prognóstico:", stack: attendanceToPdf })
                        if(terapeuticPlan) editorStateToPdf({ rawEditorState: terapeuticPlan, title: "Plano terapêutico:", stack: attendanceToPdf })
                        if(prescriptions && prescriptions[0]) prescriptions.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Prescrição nº ${index + 1}`, stack: attendanceToPdf }))
                        if(examRequests && examRequests[0]) examRequests.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Requisição de exames nº ${index + 1}`, stack: attendanceToPdf }))
                        if(attests && attests[0]) attests.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Atestado nº ${index + 1}`, stack: attendanceToPdf }))
                        if(declarations && declarations[0]) declarations.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Declaração nº ${index + 1}`, stack: attendanceToPdf }))
                        if(reports && reports[0]) reports.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Laudo nº ${index + 1}`, stack: attendanceToPdf }))
                        if(consentTerms && consentTerms[0]) consentTerms.map((editorState, index) => editorStateToPdf({ rawEditorState: editorState, title: `Termo de consentimento nº ${index + 1}`, stack: attendanceToPdf }))
                        attendanceToPdf.push({ text: "", margin: [0, 0, 0, 20] })
                        return attendanceToPdf
                      } else {
                        return null
                      }
                    }).filter(item => {
                      return item != null
                    }),
                    margin: [0, 0, 0, 20]
                  },
                ],
              },
              {
                stack: [
                  {
                    text: "EVOLUÇÃO CLÍNICA",
                    decoration: "underline",
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 20],
                  },
                  {
                    stack: chartImages.map((chartImage, index) => ([
                      { text: clinicalExams[index].parameter, alignment: "center" },
                      { image: chartImage, width: 450, height: 250 }
                    ])),
                    unbreakable: true
                  }
                ],
              },              
            ],
          }
        }        
      })
    return recordsToPdf
  }

  useEffect(() => {
    if (activeTab == "5" && !chartImages[0]) {
      setLoading(true)
      const loadScript = () => {
        var tags = document.getElementsByTagName('script')
        if (![].slice.call(tags).some(tag => {return tag.src == "https://www.gstatic.com/charts/loader.js"})) {
          var script = document.createElement('script')
          script.src = "https://www.gstatic.com/charts/loader.js"
          script.id = "charts-script"
          document.getElementsByTagName('head')[0].appendChild(script)
          return new Promise((res, rej) => {
            script.onload = function() {
              res()
            }
            script.onerror = function () {
              rej()
            }
          })
        } else {
          return new Promise((res, rej) => {
            return res()
          })
        }
      }

      loadScript()
        .then(() => {
          google.charts.load('current', {'packages':['corechart']})
          google.charts.setOnLoadCallback(drawChart)
          function drawChart () {

            clinicalExams.map(clinicalExam => {

              let chartData = [
                ["Data", "Valor"],
              ]
          
              let hTicks = {}
              let vTicks = {} 

              quantityTypes.map((quantityType, index) => {
                if (quantityType.name == clinicalExam.quantityType) {
                  if (quantityType.values[0]) {
                    quantityType.values.map((value, index) => {
                      if (!vTicks.ticks) {
                        vTicks.ticks = [{ v: 0, f: "" }]
                      }
                      vTicks.ticks = [...vTicks.ticks, { v: quantityType.values.length - index, f: value }]
                    })
                  }
                }
              })

              clinicalExam.measurements.map((measurement) => {
                let dataValue = measurement.value
                quantityTypes.map((quantityType, index) => {
                  if (quantityType.name == clinicalExam.quantityType) {
                    if (quantityType.values[0]) {
                      quantityType.values.map((value, index) => {
                        if (!vTicks.ticks) {
                          vTicks.ticks = []
                        }
                        vTicks.ticks = [...vTicks.ticks, quantityType.values.length - index]
                        if (dataValue == value) {
                          dataValue = quantityType.values.length - index
                        }
                      })
                    }
                  }
                })
                dataValue = dataValue.toString()
                dataValue = dataValue.replace(/(\d*)(,)(\d)/, "$1.$3")
                chartData.push([new Date(measurement.date), parseFloat(dataValue)])
                if (!hTicks.ticks) {
                  hTicks.ticks = []
                }
                hTicks.ticks = [...hTicks.ticks, new Date(measurement.date)]
              })

              let chartOptions = {
                titleTextStyle: {
                  fontSize: 20,
                },
                legend: "none",
                pointSize: 5,
                hAxis: {
                  title: "Data",
                  titleTextStyle: {
                    italic: false,
                    bold: true,
                  },
                  format: "dd/MM/YY",
                  ...hTicks,
                },
                vAxis: {
                  title: `${clinicalExam.quantityType} ${clinicalExam.unit != "" && clinicalExam.unit ? `(${clinicalExam.unit})` : ""}`,
                  titleTextStyle: {
                    italic: false,
                  },
                  ...vTicks
                },
              }

              const chart_div = document.createElement("div")
              chart_div.style.width = "900px"
              chart_div.style.height = "500px"
              document.getElementById('charts-div').appendChild(chart_div)

              const chart = new google.visualization.LineChart(chart_div)
              google.visualization.events.addListener(chart, 'ready', function () {
                setChartImages(chartImages => [...chartImages, chart.getImageURI()] )
              })  
              chart.draw(google.visualization.arrayToDataTable(chartData), chartOptions)
            })

          }
        })
      setLoading(false)
    }

  }, [activeTab])

  useEffect( () => {
    if ((activeTab == "2" || activeTab == "5") && !previousAttendances[0]) {
      const getPreviousAttendances = async () => {
        setLoading(true)
        await AttendancesService.searchAttendancesByPatientId({
          patientId: patient._id
        })
        .then( (response) => {
          setPreviousAttendances(
            response.data.sort((a, b) => (a.attendanceDateTime > b.attendanceDateTime) ? 1 : ((b.attendanceDateTime > a.attendanceDateTime) ? -1 : 0)) // Ordenação dos atendimentos do mais antigo para o mais novo
          )
          let previousAttendancesX = response.data.sort((a, b) => (a.attendanceDateTime > b.attendanceDateTime) ? 1 : ((b.attendanceDateTime > a.attendanceDateTime) ? -1 : 0)) // Ordenação dos atendimentos do mais antigo para o mais novo
          let atendimentos = previousAttendancesX.map((attendance, index) => {
            if (attendance.attendanceDateTime) {
              return "Yes " + index
            } else {
              return null
            }
          })
          let atendimentos2 = atendimentos.filter(atendimento => atendimento != null)
          console.log(atendimentos2)
          setLoading(false)
        })
      }
      getPreviousAttendances()
    }
    if (activeTab == "3" && !previousAttendancePackages[0]) {
      const getAttendancePackages = async () => {
        setLoading(true)
        await AttendancePackagesService.getAttendancePackagesByPatientId({
          patientId: patient._id
        })
        .then( (response) => {
          setPreviousAttendancePackages(
            response.data.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0)) // Ordenação dos atendimentos do mais antigo para o mais novo
          )
          setLoading(false)
        })
      }
      getAttendancePackages()
    }
  }, [activeTab])

  const getClinicalExams = async () => {
    await ClinicalExamsService.searchClinicalExamsByPatientId({
      patientId: patient._id
    })
    .then( (response) => {
      setClinicalExams(response.data)
    })
  }

  useEffect( () => {
    try {
      getClinicalExams()
    } catch (error) {
      console.log(error)
      history.replace("/app")
    }
  }, [])

  useEffect( () => {
    const eventListener = (event) => {
      event.preventDefault()
      if (formChangedRef.current) {
        let confirmationMessage = "Há alterações não salvas. Tem certeza que deseja sair?"
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }
    window.addEventListener('beforeunload', eventListener)
    try {
      setName(patient.name)
      setEmail(patient.email)
      methods.maskAndSetAreaCode(patient.areaCode, setAreaCode, setAreaCodeError)
      methods.maskAndSetPhoneNumber(patient.phoneNumber, setPhoneNumber, setPhoneNumberError)
      setGender(patient.gender)
      setRgIdentifier(patient.rgIdentifier)
      setRgDigit(patient.rgDigit)
      setRgUf(patient.rgUf)
      methods.maskAndSetCpf(patient.cpf, setCpf, setCpfError)
      methods.dateToString(patient.birthday, setBirthday)
      methods.maskAndSetCep(patient.cep, setCep)
      setStreet(patient.street)
      setAdressNumber(patient.adressNumber)
      setComplement(patient.complement)
      setNeighborhood(patient.neighborhood)
      setCity(patient.city)
      setUf(patient.uf)
      methods.dateToString(patient.createdAt, setCreatedAt)
      setIsActive(patient.isActive)
    } catch (error) {
      console.log(error)
    }
    return () => {
      window.removeEventListener('beforeunload', eventListener);
    }
  }, [])

  if (redirectToLogin || !patient) {
    return <Redirect to="/app"/>
  }

  return (
    <Section className="workspace-section">
      {loading && <LoadingOverlay/>}

      <WorkspaceTitle icon={faAddressCard}>
        {name}
      </WorkspaceTitle>

      <Components.PatientTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <Section className="tabs-content">

        {activeTab == "1" &&
          <form onSubmit={handleSubmit} >

            <Column.Group multiline>

              <Components.UserName
                required={true}
                name={name}
                nameError={nameError}
                setName={setName}
                setNameError={setNameError}
                setFormChanged={setFormChanged}
              />

              <Components.Email
                required={false}
                email={email}
                emailError={emailError}
                setEmail={setEmail}
                setEmailError={setEmailError}
                setFormChanged={setFormChanged}
              />

              <Components.Phone
                required={false}
                areaCode={areaCode}
                areaCodeError={areaCodeError}
                setAreaCode={setAreaCode}
                setAreaCodeError={setAreaCodeError}
                phoneNumber={phoneNumber}
                phoneNumberError={phoneNumberError}
                setPhoneNumber={setPhoneNumber}
                setPhoneNumberError={setPhoneNumberError}
                setFormChanged={setFormChanged}
              />

              <Components.Gender
                gender={gender}
                setGender={setGender}
              />

              <Components.Rg
                required={false}
                rgIdentifier={rgIdentifier}
                rgIdentifierError={rgIdentifierError}
                setRgIdentifier={setRgIdentifier}
                setRgIdentifierError={setRgIdentifierError}
                rgDigit={rgDigit}
                setRgDigit={setRgDigit}
                rgDigit={rgDigit}
                setRgDigit={setRgDigit}
                rgUf={rgUf}
                setRgUf={setRgUf}
                setFormChanged={setFormChanged}
              />

              <Components.Cpf
                required={false}
                cpf={cpf}
                cpfError={cpfError}
                setCpf={setCpf}
                setCpfError={setCpfError}
                setFormChanged={setFormChanged}
              />

              <Components.Date
                required={false}
                label="Data de Nascimento:"
                elementName="birthday"
                date={birthday}
                dateError={birthdayError}
                setDate={setBirthday}
                setDateError={setBirthdayError}
                setFormChanged={setFormChanged}
              />

            </Column.Group>

            <Column.Group multiline>

            <Components.Cep
                required={false}
                cep={cep}
                setCep={setCep}
                cepError={cepError}
                setCepError={setCepError}
                setStreet={setStreet}
                setAdressNumber={setAdressNumber}
                setComplement={setComplement}
                setNeighborhood={setNeighborhood}
                setCity={setCity}
                setUf={setUf}
                setLoading={setLoading}
                setFormChanged={setFormChanged}
              />

              <Components.Street
                required={false}
                street={street}
                setStreet={setStreet}
                setFormChanged={setFormChanged}
              />

              <Components.AdressNumber
                required={false}
                adressNumber={adressNumber}
                setAdressNumber={setAdressNumber}
                setFormChanged={setFormChanged}
              />

              <Components.Complement
                required={false}
                complement={complement}
                setComplement={setComplement}
                setFormChanged={setFormChanged}
              />

              <Components.Neighborhood
                required={false}
                neighborhood={neighborhood}
                setNeighborhood={setNeighborhood}
                setFormChanged={setFormChanged}
              />

              <Components.CityAndUf
                required={false}
                city={city}
                setCity={setCity}
                uf={uf}
                setUf={setUf}
                setFormChanged={setFormChanged}
              />

              <Components.GenericStaticField
                size={3}
                label="Cadastrado em:"
                value={createdAt}
              />

            </Column.Group>

            <Column.Group multiline>

              <Components.Switch
                label="Situação:"
                onChange={handleIsActiveChange}
                checked={isActive}
                setFormChanged={setFormChanged}
              />

            </Column.Group>

            <hr className="form-divider" />        

            <Components.SubmitButton icon={faEdit}>
              Atualizar
            </Components.SubmitButton>

            <div className="is-invisible" style={{ height: "5px" }}>{clinicalExamsComponent}</div>

          </form>

        }

        {activeTab == "2" &&
          <Fragment>
            {(JSON.parse(localStorage.getItem('user')).isProfessional || JSON.parse(localStorage.getItem('user')).isCompanyAdm) &&
              <Fragment>
                {(previousAttendances && previousAttendances[0]) &&
                  previousAttendances.map((previousAttendance, index) => (
                    <Components.PreviousAttendanceCard
                      key={index}
                      attendance={previousAttendance}
                      focusedItem={props.focusedItem}
                      setFocusedItem={props.setFocusedItem}
                      setObjectToEdit={props.setObjectToEdit}
                      setFormChanged={setFormChanged}
                      setHasUnsavedChanges={setHasUnsavedChanges}
                      setNotification={setNotification}
                      setLoading={setLoading}
                      setRedirectToLogin={setRedirectToLogin}
                    />
                  ))
                  ||
                  <Title size={5} className="has-text-centered has-text-info" >
                    Não há atendimentos para este paciente.
                  </Title>
                }
              </Fragment>
              ||
              <Title size={5} className="has-text-centered has-text-info" >
                Disponível apenas para profissional ou administrador da empresa.
              </Title>
            }
          </Fragment>
        }

        {activeTab == "3" &&
          <Fragment>
            {(previousAttendancePackages && previousAttendancePackages[0]) &&
              previousAttendancePackages.map((previousAttendancePackage, index) => (
                <Components.PreviousAttendancePackageCard
                  key={index}
                  attendancePackage={previousAttendancePackage}
                  focusedItem={props.focusedItem}
                  setFocusedItem={props.setFocusedItem}
                  setObjectToEdit={props.setObjectToEdit}
                  setFormChanged={setFormChanged}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  setNotification={setNotification}
                  setLoading={setLoading}
                  setRedirectToLogin={setRedirectToLogin}
                />
              ))
              ||
              <Title size={5} className="has-text-centered has-text-info" >
                Não há pacotes de atendimentos para este paciente.
              </Title>
            }
          </Fragment>
        }

        {activeTab == "4" &&
          <Fragment>
            {(JSON.parse(localStorage.getItem('user')).isProfessional || JSON.parse(localStorage.getItem('user')).isCompanyAdm) &&
              <Fragment>
                {clinicalExams[0] &&
                  clinicalExamsComponent
                }
              </Fragment>
              ||
              <Label>Disponível somente para o profissional responsável ou administrador da empresa</Label>
            }
          </Fragment>
        }

        {activeTab == "5" &&
          <Fragment>
            {(JSON.parse(localStorage.getItem('user')).isProfessional || JSON.parse(localStorage.getItem('user')).isCompanyAdm) &&
              <Fragment>

                <div id="charts-div" style={{ position: "absolute", opacity: "0.01" }}></div>
                
                <Button.Group align='centered'>
                  <Button
                    color="primary"
                    size="medium"
                    className="has-text-weight-bold"
                    onClick={event => {
                      let recordsToPdf = formatRecordsToPdf()
                      methods.createPatientRecordPdf({
                        recordsToPdf: recordsToPdf,
                        title: "PRONTUÁRIO",
                        logoImage: props.logoImage,
                        setSrc: setSrc,
                      })
                    }}
                  >
                    <Icon align="left">
                      <FontAwesomeIcon icon={faFilePdf} />
                    </Icon>
                    <span>Gerar Prontuário</span>
                  </Button>
                </Button.Group>

                {src &&
                  <Components.CardWithOverlay
                    cardType="widerViewer"
                    title={
                      <Fragment>
                        <span className="mr-2">{`Prontuário - ${name}`}</span>
                        <Button
                          color="success"
                          className="has-text-weight-bold is-hidden-touch"
                          onClick={event => {
                            const a = document.createElement("a")
                            document.body.appendChild(a)
                            a.style = "display: none"
                            a.href = src
                            a.download = `${DateTime.fromJSDate(new Date(Date.now())).toFormat("yyyy'-'MM'-'dd")} - Prontuário - ${name}.pdf`
                            a.click()
                          }}
                        >
                          <Icon align="left">
                            <FontAwesomeIcon icon={faDownload} />
                          </Icon>
                          <span>Baixar</span>
                        </Button>
                      </Fragment>
                    }
                    icon={faFilePdf}
                    iconColor="red"
                    setOpenCard={setSrc}
                    revokeUrl={src}
                  >
                    <Generic className="is-hidden-touch">
                      <iframe src={src} style={{ width: "100%", height: "75vh", border: "none" }}/>
                    </Generic>
                    <Generic className="is-hidden-desktop">
                      <Label>
                        {`${DateTime.fromJSDate(new Date(Date.now())).toFormat("yyyy'-'MM'-'dd")} - Prontuário - ${name}.pdf`}
                      </Label>
                      <Button.Group align="centered">
                        <Button
                          color="success"
                          className="has-text-weight-bold mt-2"
                          onClick={event => {
                            const a = document.createElement("a")
                            document.body.appendChild(a)
                            a.style = "display: none"
                            a.href = src
                            a.download = `${DateTime.fromJSDate(new Date(Date.now())).toFormat("yyyy'-'MM'-'dd")} - Prontuário - ${name}.pdf`
                            a.click()
                            setSrc(null)
                          }}
                        >
                          <Icon align="left">
                            <FontAwesomeIcon icon={faDownload} />
                          </Icon>
                          <span>Baixar</span>
                        </Button>
                      </Button.Group>
                    </Generic>
                    
                  </Components.CardWithOverlay>
                }

              </Fragment>
              ||
              <Label>Disponível somente para o profissional responsável ou administrador da empresa</Label>
            }
          </Fragment>
        }

      </Section>    

    </Section>
  )
}

export default EditPatient;